class ThAccordionGroup {
    constructor(rootNode) {
        this.rootEl = rootNode;     // .js-accordion-group
        this.accordions = this.rootEl.querySelectorAll('.js-accordion');
        this.accordionsInstances = [];          // tableau avec les instances de la classse ThAccordion

        this.init();
    }


    init() {
        this.accordions.forEach(accordion => {
            const instance = new ThAccordion(accordion);
            this.accordionsInstances.push(instance);

            accordion.addEventListener('onAccordionOpen', this.onAccordionOpen.bind(this));
        });
    }


    onAccordionOpen(e) {
        const currentInstance = e.detail.accordionInstance;

        // Referme les instances de la classe ThAccordion le cas échéant
        this.accordionsInstances.forEach(instance => {
            if (instance !== currentInstance && instance.isOpen) {
                instance.close();
            }
        });
    }
}