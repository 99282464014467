// Ajoute une classe d'état .is-pinned sur un élément sticky quand il est stické
class DetectStickyState {
    constructor(rootNode) {
        this.rootEl = rootNode;     // .js-detect-sticky
        this.init();
    }

    init() {
        // Marge pour positionner les éléments sticky sous la barre de navigation
        this.stickyOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        if (!this.stickyOffset) this.stickyOffset = 80;
        this.rootMarginTop = (this.stickyOffset + 1) * -1 + 'px';      // 1px pour la détection -> -81px

        const observer = new IntersectionObserver(([e]) => {
                e.target.classList.toggle("is-pinned", e.intersectionRatio < 1);
            },
            {
                threshold: [1],
                rootMargin: `${this.rootMarginTop} 0px 180px 0px`,
            }
        );

        this.rootEl.forEach(el => observer.observe(el) );
    }
}