const allLinks = [...document.querySelectorAll('a[href="#widget-opensystem"]')];

if (allLinks) {
    allLinks.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();

            document.querySelector(link.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        });
    });
}