(function () {

    // Ajoute un wrapper autour de chaque select
    const selectNodes = document.querySelectorAll('.bloc-form select, .facettes select');
    selectNodes.forEach(selectNode => {
        const wrapper = document.createElement('div');
        wrapper.classList.add('select-wrapper');
        wrap(selectNode, wrapper);
    });

    // Quand une checkbox / radio est cochée, ajoute une classe sur le container parent
    document.querySelectorAll('.bloc-form .frm_checkbox input[type="checkbox"]').forEach(el => {
        el.addEventListener('change', () => {
            const container = el.closest('.frm_checkbox');
            (el.checked) ? container.classList.add('is-checked') : container.classList.remove('is-checked');
        });
    });

    document.querySelectorAll('.bloc-form .frm_radio input[type="radio"]').forEach(el => {
        el.addEventListener('change', () => {
            const group = el.closest('.frm_opt_container');

            group.querySelectorAll('input[type="radio"]').forEach(el => {
                const container = el.closest('.frm_radio');
                (el.checked) ? container.classList.add('is-checked') : container.classList.remove('is-checked');
            });
        });
    });


    // Quand un switch est coché, ajoute une classe sur le container parent
    document.querySelectorAll('.bloc-form .frm_switch_block input[type="checkbox"]').forEach(el => {
        el.addEventListener('change', () => {
            const container = el.closest('.frm_switch_block');
            (el.checked) ? container.classList.add('is-checked') : container.classList.remove('is-checked');
        });
    });

    // Détecte si l'input est vide ou non si on doit gérer un effet d'entrée / sortie du label au focus on / focus focus out
    document.querySelectorAll('.bloc-form input, .bloc-form textarea, .bloc-form select').forEach(el => {
        const container = el.closest('.frm_form_field');
        if (container) {
            // valeur par défaut ou non 
            (el.value !== "") ? container.classList.add('has-content') : container.classList.remove('has-content');

            el.addEventListener('focus', () => {
                container.classList.add('has-content');
            });

            el.addEventListener('focusout', () => {
                (el.value !== "") ? container.classList.add('has-content') : container.classList.remove('has-content');
            });
        }
    });





    // ------------------------

    if ($('.bloc-form').length > 0) {


        $('form select').on('change', function () {
            var value = $(this).val();
            var value_elem = $('option[value="' + value + '"]', this);
            if (value_elem.hasClass('frm_other_trigger')) {
                $('.frm_other_input').show();
            } else {
                $('.frm_other_input').hide();
            }
            $(this).parent().parent().parent().addClass('has-content');
        });
    }


    // TYPE RADIO
    // $('.frm_radio > label').append('<span></span>');


    // TYPE CHECKBOX
    // $('.frm_checkbox > label').append('<span></span>');


    // TYPE INPUT FILE
    var currentLangFormidable = 'fr';
    var buttonUploadText = {
        'fr': "Parcourir"
    };

    $('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').addClass('jfilestyle').attr('data-buttonText', buttonUploadText[currentLangFormidable]);
    $('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').parents('.form-field').addClass('input-type-file');


    $('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').on('click', function () {
        console.log('ghj', );
        if ($(this).parents('.jfilestyle').first('input div').value != '') {
            $(this).parents('.form-field:first').addClass('has-content');
        } else {
            $(this).parents('.form-field:first').removeClass('has-content');
        }

    });

})();

