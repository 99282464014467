if (isIE()) {
    document.body.classList.add("ie");
}

if (isNoHover()) {
    document.body.classList.add("no-hover");
}

th_overlay.init();

// Fix le calcul des vh sur iOS et Chrome Android (css var --vh injectée en JS)
new calculateVheight();

// affichage des overlays possible que a partie de 2s
setTimeout(() => {
    document.body.classList.add("overlay-load");
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});


/* Detect the scroll of the page down */
document.addEventListener('scroll', (e) => {
    const st = document.documentElement.scrollTop;

    if(st > 100) {
        document.body.classList.add("is-scrolled");
    } else {
        document.body.classList.remove("is-scrolled");
    }
});


/* Detect the scroll of the page up */
let lastscrolltop = 0;
let lastIsDirTop = 0;
document.addEventListener('scroll', (e) => {
    const st = document.documentElement.scrollTop;

    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        document.body.classList.add("scrolldir-top");
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        document.body.classList.remove("scrolldir-top");
    }
    lastscrolltop = st;
});

$('a figcaption').on('touchstart click', function (e) {
    e.stopPropagation();
    e.preventDefault();
});


document.querySelectorAll('.js-player-video').forEach(el => new ThVideo(el));

document.querySelectorAll('.js-toggle-disclosure').forEach(el => new DisclosureWidget(el));

document.querySelectorAll('.js-checkbox-switch').forEach((el) => new CheckboxSwitch(el));

document.querySelectorAll('.js-combobox').forEach((el) => new ComboBox(el));


// Détecte l'état stické sur les titres de date dans le listing agenda
new DetectStickyState(document.querySelectorAll('.js-detect-sticky'));

// Initialisation de la nav des ancres
if (document.querySelector('.js-nav-anchors')) {
    new ThAnchors(document.querySelector('.js-nav-anchors'));
}


// Initialisation de la classe

document.querySelectorAll('.js-accordion-group').forEach(el => new ThAccordionGroup(el)  );

// Initialisation de la classe
document.querySelectorAll('.js-view-more').forEach(el => new ThViewMore(el));


// Scroll smooth jusqu'à une ancre
document.querySelectorAll('.js-scroll-to-target').forEach(el => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        const id = el.getAttribute('href');
        const target = document.querySelector(id);

        if (target) {
            // Enregistre la position courante du scroll avant le focus pour empêcher le défilement sur la section de sacader
            const currentScollX = window.scrollX;
            const currentScollY = window.scrollY;
            target.focus();
            window.scrollTo(currentScollX, currentScollY);      // repart de la position d'origine du scroll au moment du click

            let scrollOffset =  parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
            if (!scrollOffset) scrollOffset = 80;
            scrollOffset += 20;
            scrollToTarget(target, -scrollOffset);
        }
    });
});


