(function () {
    class MegaMenu {
        constructor(rootNode) {
            this.rootEl = rootNode.querySelector('.nav-primary');    // .js-accordion-group
            this.accordions = this.rootEl.querySelectorAll('.js-accordion');
            this.accordionsInstances = [];          // tableau avec les instances de la classse ThAccordion

            this.init();
        }


        init() {
            this.accordions.forEach(accordion => {
                const instance = new ThAccordion(accordion);
                this.accordionsInstances.push(instance);

                accordion.addEventListener('onAccordionOpen', this.onAccordionOpen.bind(this));
            });


            setTimeout(() => {
                // Désactive l'ouverture par défaut du menu de 2eme niveau sur mobile
                if (window.screen.width <= 600) {
                    this.accordionsInstances.forEach(instance => {
                        if (instance.isOpen) instance.close();
                    });
                }

            }, 1250);
        }


        onAccordionOpen(e) {
            const currentInstance = e.detail.accordionInstance;

            // Referme les instances de la classe ThAccordion le cas échéant
            this.accordionsInstances.forEach(instance => {
                if (instance !== currentInstance && instance.isOpen) {
                    instance.close();
                }
            });
        }
    }

    // Initialisation du mega menu
    new MegaMenu(document.getElementById('overlay-menu'));
})();

