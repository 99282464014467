(function () {

    const blocs = document.querySelectorAll('.bloc-localisation');
    blocs.forEach(bloc => {

        const mySvg = bloc.querySelector('.col-image > svg');
        const myMap = SVG(mySvg);
        const map_w = myMap.width();
        const map_h = myMap.height();

        // Coords des points haut/gauche et bas/droite de la map
        const map_coords = {
            top_left_lat: bloc.dataset.mapTopLeftLat,
            top_left_lng: bloc.dataset.mapTopLeftLng,
            bottom_right_lat: bloc.dataset.mapBottomRightLat,
            bottom_right_lng: bloc.dataset.mapBottomRightLng,
        }


        // coordonnées du marker à placer
        const marker_coords = {
            lat: bloc.dataset.markerLat,
            lng: bloc.dataset.markerLng,
        }

        if (marker_coords.lat && marker_coords.lng) {

            // Convertis les lat / lng exprimés en degrés, en radians
            const top = map_coords.top_left_lat * (Math.PI / 180);
            const bottom = map_coords.bottom_right_lat * (Math.PI / 180);
            const left = map_coords.top_left_lng * (Math.PI / 180);
            const right = map_coords.bottom_right_lng * (Math.PI / 180);

            const marker_lat_rad = marker_coords.lat * (Math.PI / 180);
            const marker_lng_rad = marker_coords.lng * (Math.PI / 180);

            //
            const ymin = Math.log(Math.tan((bottom / 2) + (Math.PI / 4)));
            const ymax = Math.log(Math.tan((top / 2) + (Math.PI / 4)));

            const xfactor = map_w / (right - left);
            const yfactor = map_h / (ymax - ymin);

            // conversion des lat / lng en coordonnées x / y
            const marker_x = (marker_lng_rad - left) * xfactor;
            const marker_y = (ymax - Math.log(Math.tan((marker_lat_rad / 2) + (Math.PI / 4)))) * yfactor;

            //const myMarker = myMap.rect(20, 20).fill('#00ff00').center(marker_x, marker_y);

            const myMarker = myMap.findOne('#marker-pin').transform(0);
            myMarker.move(marker_x - myMarker.width() / 2, marker_y - myMarker.height());

            myMarker.click(() => {
                console.log('CLick on marker !', marker_x, marker_y);
            });
        }

    });

})();