// JS qui concerne les single séjours
(function () {

    if (document.body.classList.contains('single-sejours')) {


        // Affichage de la sidebar en version expanded quand le titre de la cover sort du viewport
        const sidebar = document.querySelector('.sidebar-sejour');
        const barre = document.querySelector('.barre-sticky');

        let stickyOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        if (!stickyOffset) stickyOffset = 80;
        const rootMarginTop = stickyOffset * -1 + 'px';      // -80px


        // Affichage de la barre sticky (seulement en dessous de desktop) quand la sidebar sort du viewport
        if (sidebar && barre) {
            const sidebarObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        // entry.boundingClientRect.top < 0 => pour s'assurer que la sidebar a bien été scrollé au-dessus du viewport (on a bien dépassé la sidebar au scroll)
                        if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                            barre.classList.add('is-show');
                        } else {
                            barre.classList.remove('is-show');
                        }
                    });
                },
                {
                    threshold: [0],
                    rootMargin: `${rootMarginTop} 0px 0px 0px`,
                }
            );

            sidebarObserver.observe(sidebar);
        }
    }
})();

