// JS qui concerne les single SIT
(function () {

    if (document.body.classList.contains('single-sit')) {


        // Init de la carte lors de l'ouverture de l'overlay
        // cette fonction doit être déclarée avant le th_overlay.init()
        // ==========================================================================

        let mapInitialized = false;
        th_overlay.addCallbackOpen(function (overlayId) {
            if (overlayId === 'overlay-map-single-sit' && !mapInitialized) {
                setTimeout(function () {
                    th_maps.initSingleMap(document.querySelector('#carte-detail-sit'));
                    mapInitialized = true;
                }, 250);
            }
        });


        // Affichage de la sidebar en version expanded quand le titre de la cover sort du viewport
        const title = document.querySelector('.cover__title');      // déclencheur
        const sidebar = document.querySelector('.sidebar-sit');
        const barre = document.querySelector('.barre-sticky');

        let stickyOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        if (!stickyOffset) stickyOffset = 80;
        const rootMarginTop = stickyOffset * -1 + 'px';      // -80px

        if (title && sidebar) {
            const titleObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            sidebar.classList.add('is-expanded');
                        } else {
                            sidebar.classList.remove('is-expanded');
                        }
                    });
                },
                {
                    threshold: [0],
                    rootMargin: `${rootMarginTop} 0px 0px 0px`,
                }
            );

            titleObserver.observe(title);
        }


        // Affichage de la barre sticky (seulement en dessous de desktop) quand la sidebar sort du viewport
        if (sidebar && barre) {
            const sidebarObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        // entry.boundingClientRect.top < 0 => pour s'assurer que la sidebar a bien été scrollé au-dessus du viewport (on a bien dépassé la sidebar au scroll)
                        if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                            barre.classList.add('is-show');
                        } else {
                            barre.classList.remove('is-show');
                        }
                    });
                },
                {
                    threshold: [0],
                    rootMargin: `${rootMarginTop} 0px 0px 0px`,
                }
            );

            sidebarObserver.observe(sidebar);
        }
    }
})();

